import {
  api,
  createCrudService,
  responseToOptions,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const baseUrl = '/api/controltower/takeUpOfferLot'

const service = createCrudService(baseUrl, api, {
  importLogUrl: '/api/controltower/takeUpOfferLot/importLog'
})

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  Object.keys(ctx.ginOptionsByProducer).forEach((x) => {
    ctx.ginOptionsByProducer[x] = responseToOptions(ctx.ginOptionsByProducer[x])
  })
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.gin.options = async ({ itemData }) => {
    const offerId = itemData.offerId
    console.log({ itemData: { ...itemData } })
    const producerId = ctx.producerIdByOfferId[offerId]
    return ctx.ginOptionsByProducer[producerId] ?? []
  }
}

export default service
