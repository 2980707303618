import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/controltower/takeUpOffer', api, {
  importLogUrl: '/api/controltower/takeUpOffer/importLog'
})

service.processValidationContext = (_component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.producer.options = ctx.producerOptions
  fieldsById.classifier.options = ctx.classifierOptions
  fieldsById.insertedBy.options = ctx.insertedByOptions

  fieldsById.contract.onValueChange = (_changedValue, fields) => {
    const idValue = fields.id.value
    fields.contract.disabled = true
    fields.insertedBy.disabled = true
    if (idValue == null || idValue < 1) {
      fields.contract.hidden = true
      fields.insertedBy.hidden = true
      fields.status.disabled = true
    }
  }
}

export default service
