<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/takeUpOfferService'
import offerLotService from '../api/takeUpOfferLotService'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getTakeUpOfferLotMeta() {
      const takeUpOfferLotMeta = await offerLotService.getMetaWithValidation()
      helpers.processMetaDetails(
        takeUpOfferLotMeta,
        'takeUpOfferLot',
        'offerId',
        'offer',
        offerLotService
      )
      return takeUpOfferLotMeta
    },
    async getMetaDetails() {
      return await Promise.all([this.getTakeUpOfferLotMeta()])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMetaWithValidation(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'controlTower.pages')
      this.metadata = meta
    })
  }
}
</script>
